import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private apiUrl = environment.baseUrl + '/Organization';

  constructor(private http: HttpClient) { }


  fetchAgents(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/All`);
  }

  getOrganizationDetails(customerID: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${customerID}`);
  }
  submitOrganizationData(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.apiUrl, data, { headers });
  }



}
